<template>
  <div
    id="exportModal"
    class="modal show"
    tabindex="-1"
    aria-labelledby="exportModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="exportModalLabel" class="modal-title">
            Export
            {{ formatters.statementPeriod(statementPeriod) }}
          </h5>
          <button
            type="button"
            class="close"
            aria-label="Close"
            data-testid="export-close"
            @click="$emit('close')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <ErrorAlert
            v-if="error"
            :error="error"
            @close="error = null"
          ></ErrorAlert>

          <p class="alert alert-light">
            Only one export can be created per statement period. After an export
            is created, it can be downloaded here as needed.
          </p>

          <div v-if="loading" class="text-muted">Loading...</div>
          <div v-else-if="exportRecord && !exportRecord.file">
            This statement period was exported using an old version of PCOC and
            is not available for download.
          </div>
          <div v-else-if="exportRecord">
            This statement period has already been exported. Download below.
          </div>
          <div v-else>
            This statement period has yet to be exported.
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <span v-if="!exportRecord">
            <span class="badge badge-primary-light ml-1">{{ rowCount }}</span>
            Rows
          </span>
          <small v-else data-testid="sample-info">
            {{ exportRecord.created_by }} &mdash;
            {{ formatters.datetime(exportRecord.created_date) }}
          </small>
          <button
            v-if="!exportRecord"
            class="btn btn-primary"
            data-testid="sample-export"
            @click="createExport"
          >
            Create Export
          </button>
          <a
            v-else-if="exportRecord.file"
            :href="exportRecord.file"
            target="_blank"
            class="btn btn-primary"
            data-testid="sample-download"
          >
            Download
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorAlert from "./ErrorAlert"
import formatters from "../utils/formatters"
export default {
  components: {
    ErrorAlert,
  },
  props: {
    statementPeriod: { type: String, required: true },
    rowCount: { type: Number, required: true },
  },
  data() {
    return {
      formatters,
      exportRecord: null,
      loading: true,
      error: null,
    }
  },
  computed: {
    spDate() {
      return this.statementPeriod.slice(0, this.statementPeriod.indexOf("T"))
    },
  },
  async beforeMount() {
    const [err, res] = await this.$api.get(`/exports/${this.spDate}/`)
    if (res) this.exportRecord = res.data
    if (err?.response.status !== 404) this.error = err
    this.loading = false
  },
  methods: {
    async createExport() {
      const [err, res] = await this.$api.post(`/exports/`, {
        statement_period: this.spDate,
      })
      if (res) this.exportRecord = res.data
      this.error = err.toString()
    },
  },
}
</script>

<style lang="postcss" scoped>
.modal.show {
  background: rgba(0, 0, 0, 0.5);
  display: block !important;
}
</style>
