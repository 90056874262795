<template>
  <div class="d-flex">
    <div class="dropdown mr-2" @click="sampleDropdown = !sampleDropdown">
      <button
        id="dropdownMenuButton"
        class="btn btn-primary dropdown-toggle"
        type="button"
        aria-haspopup="true"
        :aria-expanded="sampleDropdown"
        :disabled="!datasource.totalRows"
        data-testid="toolbar-sample"
      >
        <PercentIcon aria-hidden class="feather-sm mr-1"></PercentIcon>
        Sample
      </button>
      <div
        class="dropdown-menu"
        :class="{ 'd-block': sampleDropdown }"
        aria-labelledby="dropdownMenuButton"
      >
        <button
          class="dropdown-item"
          title="Import a sample file"
          data-testid="toolbar-sample-import"
          @click="sampleImport = true"
        >
          Import
        </button>
        <button
          class="dropdown-item"
          title="Export a CSV for sampling"
          data-testid="toolbar-sample-export"
          @click="sampleExport = true"
        >
          Export
        </button>
      </div>
    </div>

    <div class="input-group flex-nowrap">
      <div class="input-group-prepend">
        <div class="input-group-text">
          <CalendarIcon aria-hidden class="feather-sm mr-1"></CalendarIcon>
          Statement Period
        </div>
      </div>
      <!-- change event fires on component mount with default values -->
      <MonthPickerInput
        v-if="this.$store.state.gridApi"
        id="sp-input-group"
        :default-month="today.getMonth() + 1"
        :default-year="today.getFullYear()"
        input-pre-filled
        data-testid="toolbar-statement"
        @change="updateFilter"
      ></MonthPickerInput>
    </div>
    <SampleImportModal
      v-if="sampleImport"
      @close="sampleImport = false"
    ></SampleImportModal>
    <SampleExportModal
      v-if="sampleExport"
      :statement-period="statementPeriod"
      :row-count="datasource.totalRows"
      @close="sampleExport = false"
    ></SampleExportModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { MonthPickerInput } from "vue-month-picker"
import SampleImportModal from "../components/SampleImportModal"
import SampleExportModal from "../components/SampleExportModal"
import PercentIcon from "../assets/percent.svg"
import CalendarIcon from "../assets/calendar.svg"

export default {
  components: {
    SampleImportModal,
    SampleExportModal,
    MonthPickerInput,
    PercentIcon,
    CalendarIcon,
  },
  data() {
    return {
      today: new Date(),
      sampleExport: false,
      sampleImport: false,
      sampleDropdown: false,
      statementPeriod: null,
    }
  },
  computed: {
    ...mapGetters(["datasource"]),
  },
  methods: {
    /** Update grid filter with our new input. */
    updateFilter(e) {
      const date = new Date(e.year, e.monthIndex - 1)
      this.statementPeriod = date.toISOString()
      this.datasource.defaultParams = { statement_period: this.statementPeriod }
      this.$store.state.gridApi.purgeServerSideCache()
    },
  },
}
</script>

<style lang="postcss">
/* Unscoped! style block to make month-picker fit an input group */
#sp-input-group .month-picker-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
