<template>
  <div
    id="sampleModal"
    class="modal show"
    tabindex="-1"
    aria-labelledby="sampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <form ref="form">
          <div class="modal-header">
            <h5 id="sampleModalLabel" class="modal-title">Import Sample</h5>
            <button
              type="button"
              class="close"
              aria-label="Close"
              data-testid="sample-close"
              @click="$emit('close')"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="created" class="alert alert-primary-light">
              <div data-testid="sample-success">
                Sample #{{ created.id }} successfully created.
              </div>
              <div v-if="created.existing.length">
                These edoc numbers were ignored as they have already been
                sampled:
                <pre class="m-0">{{ created.existing.join(", ") }}</pre>
              </div>
            </div>

            <ErrorAlert
              v-if="error"
              :error="error"
              @close="error = null"
            ></ErrorAlert>

            <div class="form-group">
              <label for="sample">Sample File</label>
              <div class="custom-file">
                <input
                  id="sample"
                  ref="sample"
                  name="sample"
                  type="file"
                  class="custom-file-input"
                  required
                  data-testid="sample-file"
                  @change="sample = $event.target.value"
                />
                <label class="custom-file-label" for="sample">{{
                  getFileLabel(sample)
                }}</label>
              </div>
            </div>
            <div class="form-group">
              <label for="reason">Screenshot</label>
              <div class="custom-file">
                <input
                  id="screenshot"
                  ref="screenshot"
                  name="screenshot"
                  type="file"
                  class="custom-file-input"
                  required
                  data-testid="sample-screenshot"
                  @change="screenshot = $event.target.value"
                />
                <label class="custom-file-label" for="screenshot">{{
                  getFileLabel(screenshot)
                }}</label>
              </div>
            </div>
            <div class="form-group">
              <label for="statement_period">Statement Period</label>
              <input
                id="statement_period"
                name="statement_period"
                type="date"
                class="form-control"
                placeholder="Statement Period"
                aria-label="Statement Period"
              />
            </div>
            <div class="form-group">
              <label for="reason">Reason</label>
              <select id="reason" name="reason" class="custom-select" required>
                <option selected>Select a Reason</option>
                <option value="Compliant">Compliant</option>
                <option value="Departmental">Departmental</option>
                <option value="Special Project">Special Project</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <input
              type="reset"
              class="btn btn-outline-danger"
              value="Reset"
              @click="reset"
            />

            <button
              type="button"
              class="btn btn-primary"
              data-testid="sample-submit"
              @click="submit"
            >
              <span
                v-if="inProgress"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorAlert from "./ErrorAlert"
export default {
  components: {
    ErrorAlert,
  },
  data() {
    return {
      sample: null,
      screenshot: null,
      error: null,
      created: null,
      inProgress: false,
    }
  },
  methods: {
    /** Return filename for bootstrap custom file input. */
    getFileLabel(v) {
      return v ? v.replace("C:\\fakepath\\", "") : "Choose file"
    },
    async submit() {
      this.inProgress = true
      this.error = null
      const formData = new FormData(this.$refs.form)
      formData.set(
        "statement_period",
        formData.get("statement_period") + "T00:00",
      )
      const headers = { "Content-Type": "multipart/form-data" }
      const [err, res] = await this.$api.post("samples/", formData, { headers })
      this.error = err
      if (res) {
        this.reset()
        this.created = res.data
      }
      this.inProgress = false
    },
    reset() {
      this.$refs.form.reset()
      this.error = null
      this.sample = null
      this.created = null
      this.screenshot = null
    },
  },
}
</script>

<style lang="postcss" scoped>
.modal.show {
  background: rgba(0, 0, 0, 0.5);
  display: block !important;
}
</style>
